
export default {
  name: 'ImageTextVertical',
  props: {
    imageText: {
      type: Array,
    },
  },
  methods: {
    mapClasses() {
      let classes = '';
      if (this.imageText && this.imageText.length) {
        switch (this.imageText.length) {
          case 1:
            classes = 'image-text-vertical-one';
            break;
          case 2:
            classes = 'image-text-vertical-two';
            break;
          case 3:
            classes = 'image-text-vertical-three';
            break;
        }
      }
      return classes;
    },
  },
};
